@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

body {
    font-family: 'Poppins', sans-serif;
}

.myAppBar {
    width: 100%;
    padding: 0.2rem 1rem;
    background-color: white;
    border-bottom: 2px solid transparent;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.myAppBar .Mui-selected {
    border-bottom: 2px solid #4084d8;
    background-color: white !important;
    padding: 1px 8px !important;
}

.myAppBar .Mui-selected .MuiListItemText-root {
    color: #4084d8 !important;
    margin-bottom: 0px !important;
}

.custom-form-item {
    /* Custom styles */
    @apply px-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:border-blue-500;
}

.login_form .ant-form-item {
    margin-bottom: 0.7rem !important;
}

.login_form .ant-input {
    background-color: white;
}

.signup_form .ant-form-item {
    margin-bottom: 2rem;
    width: 100%;
}

.my_form {
    padding-left: 1rem;
    padding-right: 1rem;
}

.ant-form-item {
    width: 100%;
}

.my_form .ant-form-item {
    margin-bottom: 0.1rem;
}

.my_form .ant-form-item-label {
    padding: 0 0 3px 5px;
    font-size: 12px !important;
}

.ant-input {
    background-color: #f8f8f8;
}

.ant-picker {
    width: 100%;
    background-color: #f8f8f8;
}

.ant-input-number {
    width: 100%;
    background-color: #f8f8f8;
}

.ant-select-selector {
    background-color: #f8f8f8 !important;
}

.ant-form {
    width: 100%;
}

.ant-col-16 {
    max-width: 100% !important;
}

.ant-col {
    max-width: 100% !important;
}

.ant-select-dropdown {
    z-index: 9999;
}

.ant-picker-dropdown {
    z-index: 9999;
}

/* Style the scrollbar track */
::-webkit-scrollbar {
    width: 4px; /* Width of the scrollbar */
  }
  
  /* Style the scrollbar thumb */
  ::-webkit-scrollbar-thumb {
    background: linear-gradient(to bottom, #cce6fc, #88b9e3); /* Gradient blue colors */
    border-radius: 6px; /* Rounded corners on the thumb */
  }
  
  /* Style the scrollbar thumb on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: linear-gradient(to bottom, #cce6fc, #88b9e3); /* Darker gradient on hover */
    width: 5px;
  }