$primaryColor: #060a28;
$darkBlue: #4084d8;
$primaryGrey: #c4c4c4;
$primaryGrey2: #f8f8f8;
$primaryGrey3: #ebebeb;
$textColor: #545252;
$deleteRed2: #f9320d;
$primaryPurple: #2c358d;
$primaryGreen: #00a44b;

.signUPContainer {
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  padding-left: 1rem;
  padding-right: 1rem;
}

.loginContainer {
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
}

.loginContainerTwo {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
    315deg,
    rgba(178, 230, 255, 0.3) 26%,
    rgba(25, 118, 209, 0.41082370448179273) 52%,
    rgba(255, 255, 255, 0.6) 76%
  );
}

.inputFields {
  width: 80%;
  border-radius: 30px;
  margin: 1rem;
}

.centerText {
  text-align: center;
}

.loginText {
  font-size: 1.7rem;
  font-weight: bold;
  border-bottom: 2px solid $darkBlue;
}

.loginBox {
  width: 30rem;
  min-height: 30rem;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 2rem;
  padding-right: 2rem;
}

.loginBoxContent {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 100%;

  align-items: center;
  justify-content: space-between;
  padding: 50px;
}

.loginButton {
  background-color: $darkBlue;
  color: white;
  width: 80%;
  margin: 1rem auto;
}

.color {
  background-color: #2c358d;
}

.spaceBetween {
  width: 80%;
  display: flex;
  justify-content: space-between;
}

.authBackground {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background: linear-gradient(
    116.82deg,
    rgba(209, 209, 214, 0.8) 0%,
    rgba(209, 209, 214, 0.8) 15.48%,
    rgba(0, 161, 222, 0.4) 52.2%,
    rgba(209, 209, 214, 0.8) 83.66%,
    rgba(209, 209, 214, 0.8) 100%
  );
}

.authCard {
  height: 70vh;
  width: 55vw;
  padding: 3rem 0;
  // background-color: #F5F5F5;
  position: relative;
  overflow: hidden;
  background: linear-gradient(#f5f5f5 60%, #bae0ef);
}

.authCardContent {
  width: 100%;
  height: 100%;
  z-index: 100;
  position: absolute;
  top: 0%;
  left: 0%;
  padding: 4rem 0;
  display: flex;
}

.authEllipse {
  height: 370px;
  width: 500px;
  border-radius: 50%;
  margin-top: -24%;
  margin-left: -22%;
  background: linear-gradient(
    191.1deg,
    rgba(0, 161, 222, 0.35) 34.74%,
    rgba(0, 161, 222, 0) 91.8%
  );
}

.Nameiput {
  width: 280px;
  height: 36px;
  left: 354px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  padding-left: 10px;
  font-size: 15px;
}
.secondinput {
  padding-top: 10px;
}

.paragraph {
  width: 82px;
  height: 24px;
  font-style: normal;
  font-weight: 200px;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.8);
}

.checkbox {
  display: flex;
 margin-left: 10px;
  color: #2f2f2f;
  position: relative;
  bottom: 37px;
  font-size: small;
  text-align: center;
  right: 4px;

}

.checkboxer {
 position: relative;
  display: flex;
  text-align: center;
  left: 39px;
  color: #0075ff;
  
}

.authCarded {
  height: 70vh;
  width: 59vw;
  padding: 3rem 0;
  // background-color: #F5F5F5;
  position: relative;
  overflow: hidden;
  background: linear-gradient(#f5f5f5 60%, #bae0ef);
}

.loginh1 {
  text-decoration: underline;
  margin-bottom: 2rem;
  color: #0075ff;
  font-family: poppins;
  font-size: medium;
}
.mywelcome {
  color: #0c0b0b;
  font-size: medium;
  width: 22rem;
  font-family: "Poppins";
 margin-left: 40px;
}

.linked {
  position: relative;
  color: blue;
  left: 6rem;
  bottom: 3rem;
}

.lastbuttom {
  position: relative;
  bottom: 7rem;
  width: 330px;
  height: 36px;
  text-align: center;
  padding-bottom: 12rem;
  left: 20px;
}

.lastbuttomview {
  position: relative;
  bottom: 3rem;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.7);
}

.lastbuttomcolor {
  color: #0075ff;
  text-decoration: underline;
  font-size: medium;
}

.googlelog {
  font-size: medium;
  right: 8rem;
}

.eyeshidden {
  position: relative;
  font-size: medium;
  left: 18rem;
  bottom: 23px;
}

.authCarden {
  height: 70vh;
  width: 59vw;
  padding: 3rem 0;
  // background-color: #F5F5F5;
  position: relative;
  overflow: hidden;
  background: linear-gradient(#f5f5f5 60%, #bae0ef);
}

.loginBoxContentff {
  width: 80%;
  border-radius: 30px;
  margin: 1rem;
}

.radioer {
  display: flex;
  position: relative;
}

.radio {
  display: flex;
  position: relative;
  bottom: 1rem;
  margin-bottom: 2rem;
  padding: 0rem 4px 0px;
}

.inputform{
  width: 243px;
 margin-bottom: 20px;
 position: relative;
 bottom: 20px;
 left: 20px;

}

.inputform2{
  width: 243px;
  position: relative;
  bottom: 10px;
}



