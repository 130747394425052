.customers-container {
    padding: 24px;
  }
  
  .customers-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
  }
  
  .customers-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 24px;
  }
  
  .ant-card {
    height: 100%;
  }
  
  .ant-card-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .ant-card-meta-title {
    font-size: 18px;
    font-weight: bold;
  }
  
  .ant-card-meta-description {
    font-size: 14px;
  }
  
  .ant-avatar {
    width: 64px;
    height: 64px;
    margin-right: 16px;
  }
  
  .ant-btn {
    margin-right: 8px;
  }