$primaryColor: #4084d8;
$lightBlue: #1976d2;
$primaryBackground: #f4f4f9;
$primaryGrey4: #c4c4c4;
$primaryGrey2: #f8f8f8;
$primaryGrey3: #ebebeb;
$textColor: #545252;
$lightTextColor: #929292;
$deleteRed2: #f9320d;
$primaryPurple: #2c358d;
$primaryGreen: #00a44b;

// 1rem
html {
  font-size: 87.5%; // 1rem = 14px, 14/16
}
@media (max-width: 75em) {
  html {
    font-size: 81.25%; // 1rem = 13px, 9/16 = 56.25%
  }
}
@media (max-width: 56.25em) {
  html {
    font-size: 75%; //1rem = 8px, 8/16 = 50%
  }
}
@media (min-width: 112.5em) {
  html {
    font-size: 100%; //1rem = 12px, 12/16 = 75%
  }
}

.topContainer {
  display: flex;
  justify-content: space-between;
  margin: 2rem 1rem;
}

.primaryButton {
  background-color: $primaryColor !important;
  color: white !important;
  // width: 10rem;
  font-size: 0.7rem !important;
  margin: 0 0.1rem !important;
}

.exportExcelButton {
  background-color: white !important;
  color: $primaryColor !important;
  border: 1px solid $primaryColor !important;
  // width: 10rem;
  font-size: 0.7rem !important;
  margin: 0 0.1rem !important;
}

.addButton {
  background-color: $primaryBackground !important;
  color: $textColor !important;
  border: 1px solid $primaryGreen !important;
  border-radius: 10px !important;
  width: 15rem;
  right: 0px;
  font-size: 0.8rem !important;
}

.alignRight {
  display: flex;
  justify-content: right;
  align-items: center;
}

.searchComponent {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 0 1rem auto;
}

.tableTopActions {
  display: flex;
  justify-content: space-between;
  background-color: white;
  border-bottom: 1px solid $primaryBackground;
}

.actionsIcon {
  cursor: pointer !important;
  border: none;
  background-color: transparent;
}

.item_details {
  width: 70%;
  margin: 0.3rem auto 0 auto;
  padding: 1rem;
}

.item_details_text {
  color: black !important;
  font-weight: bold !important;
  margin-bottom: 0.3rem !important;
}

.profilePicture {
  height: 100%;
  width: 100%;
}

.statsCardContainer {
  width: 98%;
  margin: 0 auto 1.5rem auto;
}

.dashboardCard {
  background-color: white;
  border-radius: 0.7rem;
  height: 11rem;
  overflow: hidden;
  padding: 1rem;
}

.dashboardCard_top {
  display: flex;
  justify-content: space-between;
}

.dashboardCard_label {
  font-weight: 500 !important;
  font-size: 1.1rem !important;
}

.dashboardCard_metric {
  height: 5rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dashboardCard_metric_value {
  font-size: 2rem !important;
  font-weight: bold !important;
}

.dashboardCard_text_metric {
  display: flex;
  align-items: center;
}

.dashboardCard_text {
  color: $textColor !important;
  margin-left: 0.3rem !important;
}

.notifications {
  background-color: white;
  border-radius: 0.7rem;
  height: 25rem;
}

.chart_container {
  background-color: white;
  border-radius: 0.7rem;
  height: 27rem;
}

.formDocument {
  background-color: white;
  width: 80%;
  margin: 1.8rem auto;
  border: 2px dashed grey;
  border-radius: 1rem;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.link {
  text-decoration: none;
  color: inherit;
  font-size: 1.2rem !important;
}

.dashboard_skeleton_card {
  height: 13.3rem !important;
  flex-grow: 1;
  margin-left: 1rem;
  margin-right: 1rem;
}

.dashboard_skeleton_cards {
  display: flex;
  justify-content: space-between;
}

.newPurchaseContainer {
  height: 90vh;
  width: 80vw;
}

.paper {
  min-width: 70vw !important;
  min-height: 85vh;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 3rem;
}

.confirmButton {
  background-color: $primaryGreen !important;
  margin: 2.4rem 0 1.5rem 0.5rem !important;
  width: 20%;
  border-radius: 1.2rem;
}

.profileContainer {
  height: 60vh;
  width: 100%;
  margin: 3rem auto 10rem auto;
}

.profileTitle {
  font-size: 1.5rem !important;
  font-weight: bold !important;
  margin-bottom: 1.2rem !important;
  text-align: center;
}

.profilePhotoCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 0.5rem;
  padding-top: 2rem;
}

.profilePicAvatar {
  height: 2rem;
  width: 2rem;
}

.profilePicture {
  height: 100%;
  width: 100%;
}

.profileName {
  font-size: 1.5rem;
  font-weight: bold;
  margin-top: 0.3rem;
  margin-bottom: 0.2rem;
}

.profileSecondaryText {
  font-size: 0.9rem;
  color: $textColor;
}

.profileInput {
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 15px;
  color: $textColor;
}

.profileLeftSection {
  border-radius: 0.5rem;
  margin-bottom: 0px;
  background-color: white;
}

.profileTopSection {
  padding: 1rem 1.5rem;
}

.profileMainSection {
  padding: 1rem 1.5rem;
}
.genericForm_column {
  padding: 0rem 4rem 0rem 4rem;
  position: relative;
  bottom: 2rem;
}

.changePasswordContainer {
  width: 100%;
  background-color: white;
  padding: 0 4rem 0 4rem;
  margin: 0 auto 6rem auto;
}

.productCard {
  background-color: white;
  margin: 0.8rem 1.2rem;
  height: 13.5rem;
  width: 13rem;
  overflow: hidden;
  border-radius: 1rem;
  padding: 0.4rem;
  cursor: pointer;
  position: relative;
  box-shadow: 5px 5px 10px #c4c4c4;
}

.productCardTop {
  display: flex;
  margin-bottom: 1rem;
}

.productCardImage {
  height: 70%;
  width: 40%;
  border: 1px solid $primaryGrey2;
  margin-right: 1rem;
}

.productName {
  font-weight: 700 !important;
  margin-top: 0.5rem !important;
}

.productDesc {
  color: $textColor;
  margin-top: 0.5rem !important;
  font-size: 0.8rem !important;
}

.productsContainer {
  display: flex;
  flex-wrap: wrap;
  margin-top: 1rem;
  overflow-y: scroll;
}

.tabs {
  border-bottom: 2px solid #c4c4c4;
  display: inline-block;
}

.listItems {
  display: flex;
}

.listItemSelected {
  .listItemText {
    background-color: $lightBlue !important;
    color: $textColor;
  }
}

.listItem {
  &:hover {
    border-bottom: 1px solid $lightBlue;
  }
}

.listItemRoot {
  color: $textColor;
  cursor: pointer;
  font-size: 0.7rem !important;
}

.myAppBar {
  width: 100%;
  padding: 0.2rem 1rem;
  background-color: white;
  border-bottom: 2px solid $primaryGrey3;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mg1Vertical {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.mg2Vertical {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.right {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
}

.userCard {
  background-color: white;
  margin: 0.8rem 1.5rem;
  height: 10rem;
  width: 20rem;
  display: flex;
  border-radius: 1rem;
  padding: 0.7rem;
  box-shadow: 5px 5px 10px #c4c4c4;
}

.userCardLeft {
  flex-basis: 35%;
}

.userCardRight {
  width: 100%;
}

.userCardName {
  font-weight: 700 !important;
  text-align: center;
  font-size: 1.2rem !important;
  margin-bottom: 1rem !important;
}

.userCardLabel {
  color: $lightTextColor !important;
  font-size: 0.9rem !important;
}

.userCardValue {
  font-size: 0.9rem !important;
}

.supplierCard {
  background-color: white;
  width: 20rem;
  padding: 1rem;
  border-radius: 15px;
  box-shadow: 5px 5px 10px #c4c4c4;
  margin: 1rem;
  max-height: 12.5rem;
}
